import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import easing from 'ui/styles/easing'

import IconStart from 'ui/components/generic/icons/Start'
import IconPause from 'ui/components/generic/icons/Pause'
import IconReset from 'ui/components/generic/icons/Reset'

export default class Timer extends Component {
  state = {
    minutes: 0,
    seconds: 0,
    timerRunning: false,
    showInputs: true,
    flashing: false,
    hasEnded: false
  }

  componentDidMount() {
    document.addEventListener('keydown',  e => {
      if(e.keyCode === 32 || e.keyCode === 13) {
        this.state.timerRunning ? this.stopTimer() : this.startTimer()
      }

      if(e.keyCode === 8) {
        this.resetTimer()
      }
    })
  }

  startTimer() {
    const { minutes, seconds, flashing } = this.state
    
    let computedTime = parseInt(minutes) * 60 + parseInt(seconds)

    if(!computedTime) {
      computedTime = 600
      
      this.setState({
        minutes: 10,
        computedTime: 600,
        hasEnded: false
      })
    }
    
    this.setState({
      timerRunning: true,
      showInputs: false,
      computedTime
    })
    
    
    this.timer = setInterval(() => {
      computedTime--
      
      if(computedTime) {
        const minutes = Math.floor(computedTime / 60)
        const seconds = computedTime - minutes * 60

        if(computedTime <= 5 && !flashing) {
          this.setState({ flashing: true })
        }
        
        this.setState({ minutes, seconds, computedTime })
      } else {
        this.setState({
          minutes: 0,
          seconds: 0,
          flashing: false,
          hasEnded: true
        })
        
        this.stopTimer()
      }
    }, 1000)
  }
  
  stopTimer() {
    this.setState({ timerRunning: false })
    
    clearInterval(this.timer)
  }
  
  resetTimer() {
    clearInterval(this.timer)
    
    this.setState({
      minutes: 0,
      seconds: 0,
      timerRunning: false,
      showInputs: true,
      hasEnded: false,
      flashing: false
    })
  }
  
  render() {
    const {
      minutes,
      seconds,
      timerRunning,
      showInputs,
      flashing,
      hasEnded
    } = this.state

    return(
      <Wrapper flashing={flashing} hasEnded={hasEnded}>
        <TimerContainer>
          {showInputs ? (
            <TimeInput>
              <DigitInput>
                <Desc>Minutes</Desc>
                <Input
                  value={minutes || ''}
                  type="number"
                  step="1"
                  min="0"
                  placeholder="10"
                  onChange={e => this.setState({ minutes: e.currentTarget.value })}
                  />
              </DigitInput>
              <p>:</p>
              <DigitInput>
                <Desc>Seconds</Desc>
                <Input
                  value={seconds || ''}
                  type="number"
                  step="1"
                  min="0"
                  placeholder="00"
                  onChange={e => this.setState({ seconds: e.currentTarget.value })}
                />
              </DigitInput>
            </TimeInput>
          ) : (
            <TimeDisplay>
              <Digits>
                <Desc>Minutes</Desc>
                {minutes < 10 ? `0${minutes}` : minutes}
              </Digits>
              <p>:</p>
              <Digits>
                <Desc>Seconds</Desc>
                {seconds < 10 ? `0${seconds}` : seconds}
              </Digits>
            </TimeDisplay>
          )}
          <Controls>
            <button onClick={() => timerRunning ? this.stopTimer() : this.startTimer()}>
              <Icon>
                {timerRunning ? <IconPause /> : <IconStart />}
              </Icon>
              {timerRunning ? 'Pause' : 'Start'}
            </button>
            <button onClick={() => this.resetTimer()}>
              <Icon>
                <IconReset />
              </Icon>
              Reset
            </button>
          </Controls>
        </TimerContainer>
      </Wrapper>
    )
  }
}

// Styles
const flashingAnim = keyframes`
  0% {
    background: #fff;
  }

  100% {
    background: none;
  }
`

const endedAnim = keyframes`
  0% {
    background: #fff;
  }

  75% {
    background: #fff;
  }

  100% {
    background: none;
  }
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: ${props => props.hasEnded ? '3s' : '1s'};
  animation-iteration-count: ${props => props.hasEnded ? 1 : 'infinite'};
  animation-name: ${props => props.flashing ? flashingAnim : props.hasEnded ? endedAnim : null};
  animation-timing-function: ${easing.easeInOutCubic};
`

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`

const TimeDisplay = styled.div`
  font-size: 10rem;
  display: flex;
  width: 100%;
  justify-content: center;
  height: 1em;
  align-items: baseline;

  p {
    margin: 0;
  }
`

const Digits = styled.p`
  position: relative;
  margin: 0;
  min-width: 1.5em;
  text-align: center;
`

const Desc = styled.span`
  width: 100%;
  text-align: center;
  position: absolute;
  font-size: ${props => props.theme.fontSizes.sm};
  bottom: 100%;
  color: rgba(255, 255, 255, 0.35);
  margin: 0;
  display: block;
`

const TimeInput = styled(TimeDisplay)`
  p {
    color: ${props => props.theme.colors.tones[0]};
  }
`

const DigitInput = styled(Digits)``

const Input = styled.input`
  font-family: ${props => props.theme.font};
  position: relative;
  margin: 0;
  width: 1.5em;
  text-align: center;
  background: none;
  border: none;
  color: white;
  outline: none;
  line-height: normal;
  line-height: 1em;
  height: 1em;
  appearance: none;
  padding: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: white;
    opacity: 0.5;
  }
`

const Controls = styled.div`
  margin-top: 3rem;

  button {
    font-size: ${props => props.theme.fontSizes.sm};
    background: none;
    border: none;
    color: ${props => props.theme.colors.tones[0]};
    text-transform: uppercase;
    margin: 0 1em;
    outline: none;
    text-align: center;
    transition: color 0.3s ${easing.easeOutCubic};

    &:hover {
      color: white;
      cursor: pointer;

      svg path {
        fill: white;
      }
    }
  }
`

const Icon = styled.div`
  width: 40px;
  margin-bottom: 1em;

  svg {
    width: 100%;
    height: auto;

    path {
      transition: fill 0.3s ${easing.easeOutCubic};
      fill: ${props => props.theme.colors.tones[0]};
    }
  }
`
